<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          
          <div class="col-span-6 sm:col-span-3">
            <label for="first_name" class="block text-sm font-medium text-gray-700">Title</label>
            <input type="text" name="first_name" id="first_name" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.title">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="meta_title" class="block text-sm font-medium text-gray-700">Meta title</label>
            <input type="text" name="meta_title" id="meta_title" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.meta_title">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="meta_description" class="block text-sm font-medium text-gray-700">Meta description</label>
            <input type="text" name="meta_description" id="meta_description" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.meta_description">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="subtitle" class="block text-sm font-medium text-gray-700">Subtitle</label>
            <input type="text" name="subtitle" id="subtitle" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.subtitle">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="icon" class="block text-sm font-medium text-gray-700">Icon</label>
            <input type="text" name="icon" id="subtitle" autocomplete="icon" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.icon">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
            <TinyMceEditor v-model="c.description" model_name="BusinessUnit" :model_id="c.id" />
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="description_jobs" class="block text-sm font-medium text-gray-700">Description (werken bij)</label>
            <TinyMceEditor v-model="c.description_jobs" model_name="BusinessUnit" :model_id="c.id" :image=true />
          </div>
          
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{loading ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import BusinessUnitService from '../../../services/admin/business_units/service';
import TinyMceEditor from '../generic/TinyMceEditor.vue';

export default {
  name: 'CaseFormComponent',
  props: ['business_unit'],
  components: {
    TinyMceEditor
  },
  setup(props) {
    const loading = ref(true);
    loading.value = false;

    return {
      c: props.business_unit,
      loading
    }
  },
  methods: {
    async handleSave() {
      this.loading = true;
      BusinessUnitService.save(this.business_unit);
      this.loading = false;
    }
  }
}
</script>
